<template>
	<div class="privacy_policy">
		<SubHeader title="Privacy policy" subtitle="プライバシーポリシー" />

		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<h2 class="mb-5">OSAKA DARTS ORGANIZATION（以下ODO）におけるプライバシー保護の考え方は以下のとおりです。</h2>
					<ol>
						<li>会員から個人情報を収集させていただく場合は、収集目的をお知らせした上で、必要な範囲で個人情報を収集させていただきます。</li>
						<li>個人情報を収集目的の範囲内で利用するとともに、適切な方法で管理し、特段の事情がない限り、会員の承諾なく第三者に開示・提供することはありません。</li>
						<li>個人情報を正確かつ最新の状態に保ち、個人情報の紛失、破壊、改ざん及び漏えい等を防止するための措置を講じております。</li>
						<li>ODOが、個人情報の処理を外部へ委託する場合には、漏えいや再提供を行わないよう契約により義務づけ、適切な管理を実施させていただきます。</li>
						<li>委託元よりお預かりした個人情報は、厳正なる管理を行い契約の範囲内で利用します。</li>
						<li>個人情報の確認、訂正等を希望される場合には、ODO事務局までご連絡いただければ、合理的な範囲ですみやかに対応させていただきます。</li>
						<li>ODOが保有する個人情報に関して適用される法令、規範を遵守するとともに、上記各項における取り組み及び保護活動を、維持、改善してまいります。</li>
						<li>ODO が会員から質問・要望、資料の請求等を通じて個人情報の提供を受けることがあります。この場合、ODOはお預かりした個人情報を、会員への回答や連絡、送付、及び収集の際に明示した目的で利用いたします。なお、法人等の団体に所属する役職員の方の所属部署・氏名・メールアドレス等については、当該団体と ODOとの契約の履行、取引にかかわる諸連絡、ODOの取り扱うサービス等の提案・案内、及び当該団体に有益と思われる情報の提供のための連絡先として使 用させていただきます。</li>
						<li>ODOは、ご本人より書面やWebメールを通じて直接個人情報を収集する場合は、提供時の状 況から利用目的が明らかである場合を除き、その都度、利用目的を明示させていただきます。また、第三者を経由して間接的に個人情報を収集する場合も、事前 もしくは事後すみやかに、利用目的をご本人に対し明示又は通知するよう努めております。</li>
						<li>ODO では、業務を円滑に遂行するため、業務の一部を委託先に委託し、当該委託先に対し必要な範囲で会員の個人情報を預託する場合がありますが、この場合は、 ODOが定めた基準を満たす者を委託先として選定するとともに、個人情報の取り扱いに関する契約の締結や適切な監督を行います。</li>
						<li>
							ODOは、会員より取得させていただいた個人情報を適切に管理し、第三者に提供することはありません。
							但し、次の場合は除きます。
							<ul>
								<li>ご本人の同意がある場合</li>
								<li>法令に基づく場合</li>
								<li>人の生命、身体又は財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
								<li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</li>
								<li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
							</ul>
						</li>

						<li>
							ODOでは、個人情報保護法に基づき、以下の各号に定める手続きに従い保有個人情報のご本人又はその代理人からの開示・変更・利用目的の通知等の求めに対応させていただきます。
							なお、ODOの会員が登録情報の照会・訂正等をご希望の場合は、下記手続きによらずとも、問い合わせ窓口にて可能な範囲で対応させていただきます。
							（但し、会員のご希望の内容によっては以下の手続きをお願いすることがありますのであらかじめご了承ください。）
							<ul>
								<li>
									(1)「開示等の求め」の申出先
									<br />開示等の求めは下記宛、所定の申請書に必要書類・手数料を添付の上、郵送によりお願い申し上げます。なお、封筒に朱書きで「開示等請求書類在中」とお書き添えいただければ幸いです。
									<div class="card my-4">
										<div class="card-body">
											〒558-0004
											<br />大阪市住吉区長居東4-7-15
											<br />田中ビル３F
											<br />大阪ダーツオーガニゼイション事務局
											<br />TEL&FAX：06-6691-8500
										</div>
									</div>
								</li>
								<li>
									(2)「開示等の求め」に際して提出すべき書面（様式）等
									<br />「開示等の求め」を行う場合は、次の申請書（A）の様式に従いすべての事項をご記入の上、ご本人確認のための書類（B）を同封しご郵送ください。
									<ul class="no-style ml-4">
										<li>
											A 申請書様式
											<br />以下の内容をすべてご記入下さい。
											<ol>
												<li>対象者者氏名、捺印</li>
												<li>対象者の生年月日</li>
												<li>対象者の現住所</li>
												<li>対象者の電話番号</li>
												<li>開示を請求される内容</li>
												<li>個人情報に関する請求理由</li>
											</ol>
										</li>
										<li>
											B ご本人確認のための書類
											<br />運転免許証、健康保険証又はパスポートのいずれかのコピー1点
										</li>
									</ul>
								</li>
								<li>
									(3)代理人による「開示等の求め」
									<br />「開示等の求め」をする者が代理人である場合は、前項の書類に加えて、下記の書類（A、B又はC）を同封ください。
									<ul class="no-style ml-4">
										<li>
											A 親権者、未成年後見人の場合
											・戸籍謄本（親権者の場合は扶養家族が記入された健康保険証のコピーも可）1通
										</li>
										<li>
											B 補助人、保佐人、成年後見人、任意後見人の場合
											・後見登記事項証明書 1通
										</li>
										<li>
											C 委任による代理人の場合
											・委任状 1通（実印によるもの）
											・ご本人の印鑑証明書 1通
										</li>
									</ul>
								</li>
								<li>
									(4)「開示等の求め」の手数料及びその徴収方法
									<br />開示・利用目的の通知の求めの場合に限り、1回の申請ごとに、以下の金額（ODOからの返信のための書留郵便費を含む）を申し受けます。
									<div class="card my-4">
										<div class="card-body">1,000円</div>
									</div>1,000円分の郵便切手を申請書類に同封してください。
									<br />＊手数料が不足していた場合、及び手数料が同封されていなかった場合は、その旨ご連絡申し上げますが、所定の期間内にお支払いがない場合は、開示の求めがなかったものとして対応させていただきます。
								</li>
								<li>
									(5)「開示等の求め」に対する回答方法
									<br />申請者の申請書記載住所宛に書面によってご回答申し上げます。
								</li>
								<li>
									(6)開示等の求めに関して取得した個人情報の利用目的
									<br />開示等の求めにともない取得した個人情報は、開示等の求めに必要な範囲のみで取り扱うものとします。提出いただいた書類は、開示等の求めに対する回答が終了した後、2年間保存し、その後廃棄させていただきます。
								</li>
								<li>
									(7)「保有個人情報」の不開示事由について
									<br />次に定める場合は、不開示とさせていただきます。不開示を決定した場合は、その旨、理由を付記して通知申し上げます。また、不開示の場合についても手数料の返還は行いませんのでご容赦ください。
									<br />
									<ul class="ml-4">
										<li>申請書に記載されている住所・ご本人確認のための書類に記載されている住所・ODOの登録住所が一致しないときなどご本人が確認できない場合</li>
										<li>代理人による申請に際して、代理権が確認できない場合</li>
										<li>所定の申請書類に不備があった場合</li>
										<li>開示の求めの対象が「保有個人情報」に該当しない場合</li>
										<li>ご本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
										<li>ODOの業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
										<li>他の法令に違反することとなる場合</li>
									</ul>
								</li>
							</ul>
						</li>
					</ol>以上。
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";

export default {
	name: "privacy_policy",
	components: {
		SubHeader
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.3rem;
	@include sp {
		font-size: 1rem;
	}
}
.container {
	padding: 4rem 1rem;
	text-align: left;
	ol {
		margin: 1rem 0;
		margin-left: 2rem;
		li {
			margin-bottom: 1rem;
			ul {
				margin: 1rem 0;
				.no-style {
					list-style: circle;
				}
			}
		}
	}
}
</style>